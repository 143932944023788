const authProvider = {
   login: (params) => Promise.resolve(),
   logout: (params) => {
      const auth2 = window.gapi.auth2.getAuthInstance();
      return auth2.signOut().then(() => {
         auth2.disconnect();
         localStorage.removeItem('packagesAccessToken');
      });
   },
   checkAuth: (params) => {
      const token = localStorage.getItem('packagesAccessToken');
      if (!token) {
         return Promise.reject();
      }
      return Promise.resolve();
   },
   checkError: (error) => Promise.resolve(),
   getPermissions: (params) => Promise.resolve(),
};
export default authProvider;

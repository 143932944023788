import React from 'react';
import {
   List, Datagrid, TextField,
} from 'react-admin';
import UsersFilter from './UsersFilter';

const UsersList = (props) => (
   <List {...props} filters={<UsersFilter />}>
      <Datagrid rowClick="edit">
         <TextField source="username" />
         <TextField source="packagesCount" />
         <TextField source="description" />
      </Datagrid>
   </List>
);
export default UsersList;

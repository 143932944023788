import React, { Component } from 'react';
import './index.css';
import { Admin, Resource } from 'react-admin';
import { UsersList, UserEdit, UserCreate } from 'pages/user';
import authProvider from 'auth/authProvider';
import dataProvider from 'api/dataProvider';
import { PackagesList, PackageAdd } from 'pages/packages';
import MyAppBar from 'components/AppBar';
import { Layout } from 'react-admin';

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

class App extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isSignedIn: false,
      };
   }

   componentDidMount() {
      this.initializeGoogleSignin();
   }

   componentDidCatch = (error, errorInfo) => {
      this.setState({
         isSignedIn: false,
      });
   }

   initializeGoogleSignin = () => {
      window.gapi.load('auth2', () => {
         this.auth2 = window.gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
         });
         this.auth2.isSignedIn.listen(this.signinChanged);
      });

      window.gapi.load('signin2', () => {
         this.renderSignInButton();
      });
   }

   signinChanged = (loggedIn) => {
      var token = window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
      if (token) {
         localStorage.setItem('packagesAccessToken', token);
         this.setState({
            isSignedIn: this.auth2.isSignedIn.get(),
         });
      } else {
         this.setState({
            isSignedIn: loggedIn,
         });
         localStorage.removeItem('packagesAccessToken');
         this.renderSignInButton();
      }
   }

   renderSignInButton = () => {
      const opts = {
         width: 250,
         height: 50,
         client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
         onfailure: this.onLoginFailed,
         longtitle: 'Sign in with Google',
         theme: 'dark',
      };
      window.gapi.signin2.render('loginButton', opts);
   }

   onLoginFailed = (err) => {
      this.setState({
         isSignedIn: false,
         error: err,
      });
   }

   render() {
      if (this.state.isSignedIn) {
         return (
            <Admin dataProvider={dataProvider} authProvider={authProvider} layout={MyLayout}>
               <Resource name="users" list={UsersList} edit={UserEdit} create={UserCreate} />
               <Resource name="packages" list={PackagesList} create={PackageAdd}/>
            </Admin>
         );
      }
      return (
         <div style={{ display: 'flex', height: '500px', justifyContent: 'center' }}>
            <div style={{ alignSelf: 'center' }} id="loginButton" />
         </div>
      );
   }
}

export default App;

import React from 'react';
import {
   TextInput,
   SimpleForm,
   Create,
   required,
} from 'react-admin';


const Title = () => 'Add new package';

const PackageAdd = (props) => (
   <Create title={<Title />} {...props}>
      <SimpleForm>
         <TextInput source="repoUrl" validate={required()} />
      </SimpleForm>
   </Create>
);
export default PackageAdd;

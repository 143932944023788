import React from 'react';
import { Field, useForm } from 'react-final-form';
import { useQueryWithStore } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
   icon: {
      marginLeft: theme.spacing(13),
      marginTop: theme.spacing(1),
   },
}));

const PackagesInput = ({ record }) => {
   const classes = useStyles({});
   const form = useForm();
   const { data, loading } = useQueryWithStore({
      type: 'getList',
      resource: 'packages',
   });

   if (loading) {
      return (
         <CircularProgress
            className={classes.icon}
            size={30}
            thickness={2}
         />
      );
   }

   const getFieldName = (id) => `package[][${id}]`;
   const userPackages = record.packages ? record.packages.map((pck) => pck.id) : [];
   const belongsToUser = (id) => {
      if (userPackages.includes(id)) {
         form.change(getFieldName(id), true);
      }
   };

   return (
      <div>
         {
            data
               .sort((first, second) => get(first, 'name', '').localeCompare(get(second, 'name', '')))
               .map((pck) => {
                  belongsToUser(pck.id);
                  return (
                     <Field
                        key={pck.id}
                        name={getFieldName(pck.id)}
                        type="checkbox"
                     >
                        {(props) => (
                           <div>
                              <input {...props.input} />
                              {pck.name}
                           </div>
                        )}
                     </Field>
                  );
               })
         }
      </div>
   );
};

export default PackagesInput;

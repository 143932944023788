import React from 'react';
import {
   TextInput,
   SimpleForm,
   Edit,
   required,
} from 'react-admin';
import UserTitle from './UserTitle';
import PackagesInput from './PackagesInput';


const UserEdit = (props) => (
   <Edit title={<UserTitle />} {...props}>
      <SimpleForm>
         <TextInput disabled label="Id" source="id" />
         <TextInput source="username" validate={required()} />
         <TextInput source="password" />
         <TextInput multiline source="description" />
         <PackagesInput />
      </SimpleForm>
   </Edit>
);
export default UserEdit;

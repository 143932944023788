import React from 'react';
import {
   Filter, TextInput,
} from 'react-admin';

const PackagesFilter = (props) => (
   <Filter {...props}>
      <TextInput label="Package name" source="url" alwaysOn />
   </Filter>
);

export default PackagesFilter;
